<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4 pb-6">
      <div class="row mb-4 text-center">
        <div class="col-md-12">
          <h3>Add New Field</h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <label class="form-control-label">Name</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Provide Name"
            v-model="data.named"
            rows="4"
          />
          <label
            v-for="error in errors.named"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
        <div class="col-md-6">
          <label class="form-control-label">Type</label>
          <span class="ml-1 text-danger">*</span>
          <select
            class="form-control form-control-alternative"
            placeholder="Provide Type"
            v-model="data.type"
            rows="4"
          >
           <option value="boolean">boolean</option>
          <option value="input">input</option>
          </select>
         
          <label
            v-for="error in errors.type"
            :key="error"
            class="form-control-flush text-danger text-sm"
            >{{ error }}</label
          >
        </div>
        
      </div>
    
      <div class="row">
        <div class="col-md-8 mt-2">
          <button class="btn btn-secondary px-6" @click="this.$router.go(-1)">
            Cancel
          </button>
          <!-- <button-loader :title="btnTitle" :classes="classes" :show_loader="show_loader" @click="savefieldCatalog()"/> -->
          <button
            :disabled="show_loader"
            class="btn btn-md btn-default my-2 px-4"
            :style="!show_loader ? '' : styleObject"
            @click="createField()"
          >
            Add Field
            <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {setDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
export default {
  data() {
    return {
      selected: 0,
      data: {},
      errors: {},

      // button styles
      styleObject: {
        cursor: "not-allowed",
      },
      btnTitle: "Add Field",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
  methods: {
    createField: function() {
      this.show_loader = true;
      // const FieldRef = Field(db, "fields").doc(this.data.name)
      setDoc(doc(db, "fields" ,this.data.named), {
        ...this.data
      })
        .then(() => {
          this.show_loader = false;
          console.log("create successfully");
          alert.success_center("You have successfully added a field");
          this.$router.push({ name: "Fields" });
        })
        .catch((e) => {
          alert.error("an error occured please try again");
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
